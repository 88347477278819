import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Wheel } from 'react-custom-roulette';
import logohey from './assets/logo-hey.png'

const AppContainer = styled.div`
  display: flex;
  height: auto;
  min-height: 100dvh;
  overflow: auto;
  touch-action: pan-x pan-y; /* Permite el desplazamiento en ambas direcciones */
  -webkit-overflow-scrolling: touch;
`;

const DataContainer = styled.div`
  text-align: center;
  flex: 0 0 300px;
  width: 30dvw;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #EEEEF0;
`;

const WheelContainer = styled.div`
  z-index: 1;
  flex: 1;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #474747;
  -webkit-box-shadow: 0px 0px 25px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 25px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 25px -3px rgba(0,0,0,0.75);
`;

const PrizeList = styled.div`
  text-align: center;
  flex: 0 0 300px;
  width: 30dvw;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #EEEEF0;
`;

const Button = styled.button`
  background-color: #181818;
  color: white;
  margin-top: 10px;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  height: 40px;
  min-width: 200px;
  opacity: 1;

  &:disabled {
    background-color: #DADCDD;
    cursor:  not-allowed;
    opacity: 0.7;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.textarea`
  padding: 8px;
  font-size: 14px;
  margin-right: 10px;
  width: 200px;
  height: 100px;
`;

const App = () => {
  const [premios, setPremios] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [nuevosParticipantes, setNuevosParticipantes] = useState('');
  const [premiosActivos, setPremiosActivos] = useState(0);
  const [nuevosPremios, setNuevosPremios] = useState('');
  const [lastGanador, setLastGanador] = useState(null);
  const [ganadores, setGanadores] = useState({});
  const [currentPrizeIndex, setCurrentPrizeIndex] = useState(0);
  const [mustSpin, setMustSpin] = useState(false);
  const [winningIndex, setWinningIndex] = useState(null);

  const buttonRef = useRef(null);

  const agregarParticipantes = () => {
    const nombres = nuevosParticipantes
      .split(/[;\n]/)
      .map((nombre) => nombre.trim())
      .filter((nombre) => nombre !== '');

    if (nombres.length === 0) {
      alert("Por favor ingresa al menos un nombre válido.");
      return;
    }

    setParticipantes([...participantes, ...nombres]);
    setNuevosParticipantes('');
  };

  const agregarPremios = () => {
    const listaPremios = nuevosPremios
      .split(/[;\n]/)
      .map((premio) => premio.trim())
      .filter((premio) => premio !== '');

    if (listaPremios.length === 0) {
      alert("Por favor ingresa al menos un premio válido.");
      return;
    }

    setPremios([...premios, ...listaPremios]);
    setPremiosActivos(premiosActivos + listaPremios.length);
    setNuevosPremios('');
  };

  const iniciarRuleta = () => {
    scrollToCenter();
    buttonRef.current.disabled = true;
    if (currentPrizeIndex >= premios.length) {
      alert("Todos los premios han sido sorteados.");
      return;
    }

    const randomIndex = Math.floor(Math.random() * participantes.length);
    setWinningIndex(randomIndex);
    setMustSpin(true);
  };

  const onStopSpinning = () => {
    if (winningIndex !== null) {
      const ganador = participantes[winningIndex];
      const premioActual = premios[currentPrizeIndex];

      setGanadores((prevGanadores) => ({ ...prevGanadores, [currentPrizeIndex + premioActual]: ganador }));
      setLastGanador(ganador);

      setTimeout(() => {
        setCurrentPrizeIndex(currentPrizeIndex + 1);
        setParticipantes((prevParticipantes) =>
          prevParticipantes.filter((_, index) => index !== winningIndex)
        );
        setPremiosActivos(premiosActivos - 1);

        setLastGanador(null);
        buttonRef.current.disabled = false;
      }, 5000);
    }

    setMustSpin(false);
  };

  const data = participantes.map((participante) => ({
    option: participante,
    optionSize: 1,
    style: {
      textColor: "white",
      fontSize: participantes.length > 150 ? 1 : 10,
    },
  }));

  const limpiarDatos = () => {
    localStorage.clear();
    window.location.reload();
  };

  const descargarCSV = () => {
    const rows = [["Premio", "Ganador"]];

    // Convertir el objeto `ganadores` a un formato de filas
    for (const [premio, ganador] of Object.entries(ganadores)) {
      rows.push([premio, ganador]);
    }

    // Convertir las filas a formato CSV
    const csvContent = rows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Crear un enlace de descarga
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ganadores.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const scrollToCenter = () => {
    const centerMarker = document.getElementById("ruleta");
  centerMarker.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center"
  });
  }

  useEffect(() => {
    const loadDataFromLocalStorage = () => {
      try {
        const storedParticipantes = JSON.parse(localStorage.getItem('participantes')) || [];
        const storedPremios = JSON.parse(localStorage.getItem('premios')) || [];
        const storedPremiosActivos = parseInt(localStorage.getItem('premiosActivos'), 10) || 0;
        const storedGanadores = JSON.parse(localStorage.getItem('ganadores')) || {};
        const storedCurrentPrizeIndex = parseInt(localStorage.getItem('currentPrizeIndex'), 10) || 0;

        setParticipantes(storedParticipantes);
        setPremios(storedPremios);
        setPremiosActivos(storedPremiosActivos);
        setGanadores(storedGanadores);
        setCurrentPrizeIndex(storedCurrentPrizeIndex);
      } catch (error) {
        console.error("Error al cargar datos de localStorage", error);
      }
    };

    loadDataFromLocalStorage();
  }, []);

  useEffect(() => {
    const saveDataToLocalStorage = () => {
      try {
        localStorage.setItem('participantes', JSON.stringify(participantes));
        localStorage.setItem('premios', JSON.stringify(premios));
        localStorage.setItem('premiosActivos', premiosActivos);
        localStorage.setItem('ganadores', JSON.stringify(ganadores));
        localStorage.setItem('currentPrizeIndex', currentPrizeIndex);
      } catch (error) {
        console.error("Error al guardar datos en localStorage", error);
      }
    };

    saveDataToLocalStorage();
  }, [participantes, premios, premiosActivos, ganadores, currentPrizeIndex]);

  const premioActual = currentPrizeIndex < premios.length ? premios[currentPrizeIndex] : "Todos los premios han sido sorteados";

  return (
    <AppContainer className=''>
      <DataContainer className='col pt-3'>
        <h2>Agregar Datos</h2>
        <InputContainer>
          <Input
            value={nuevosParticipantes}
            onChange={(e) => setNuevosParticipantes(e.target.value)}
            placeholder="Nombres de participantes (separados por comas, punto y coma o saltos de línea)"
          />
          <Button onClick={agregarParticipantes}>Agregar Participantes</Button>
        </InputContainer>

        <InputContainer>
          <Input
            value={nuevosPremios}
            onChange={(e) => setNuevosPremios(e.target.value)}
            placeholder="Nombres de premios (separados por comas, punto y coma o saltos de línea)"
          />
          <Button onClick={agregarPremios}>Agregar Premios</Button>
        </InputContainer>
        {participantes.length > 0 || premios.length > 0 ?
          <Button onClick={limpiarDatos} style={{ position: 'absolute', bottom: 15, backgroundColor: '#FF5C5C' }}>
            Limpiar Datos
          </Button>
          : <></>
        }
      </DataContainer>

      <WheelContainer className='col pt-3 pb-3'>
        <h1>Sorteo de Premios</h1>
        <div style={{ color: 'white' }}>Participantes: {participantes.length}</div>
        <div style={{ color: 'white' }}>Premios: {premiosActivos}</div>

        {participantes.length > 0 && (
          <div id='ruleta'
            style={{
              background: "#141D27",
              aspectRatio: "auto",
              borderRadius: 360,
              margin: '20px',
              backgroundImage: `url(${logohey})`,
              backgroundSize: '80px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={winningIndex !== null ? winningIndex : -1}
              data={data}
              backgroundColors={["#181818", "#DADCDD", "#3886FF", "#E74F5A", "#BAEC40", "#F8E32E", "#BCB3FD", "#FFB15E", "#FEA0F7", "#84E5F6"]}
              onStopSpinning={onStopSpinning}
              outerBorderWidth={5}
              outerBorderColor={"grey"}
              innerRadius={30}
              innerBorderWidth={5}
              innerBorderColor={"grey"}
              radiusLineWidth={0}
              spinDuration={1.0}
            />
          </div>
        )}

        <div style={{ margin: '10px', fontSize: '20px', fontWeight: 'bold', color: 'white' }}>{`${premioActual}`}</div>
        {lastGanador && <div style={{ margin: '10px', fontSize: '40px', fontWeight: 'bold', color: 'white' }}>{`${lastGanador}`}</div>}

        <Button ref={buttonRef} onClick={iniciarRuleta} disabled={participantes.length === 0 || premios.length === 0 || currentPrizeIndex >= premios.length}>
          {currentPrizeIndex < premios.length ? 'Girar Ruleta' : 'Sorteo Finalizado'}
        </Button>
      </WheelContainer>

      <PrizeList className='col pt-3'>
        <h2>Lista de Ganadores</h2>
        <hr />
        <div style={{ overflowY: 'auto', height: 'calc(100dvh - 150px)', marginLeft: '30px', marginRight: '30px' }}>
          {premios.map((premio, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <div style={{ fontWeight: 'bold', margin: 0, padding: 0 }}>{premio}</div>
              <div>{ganadores[index + premio] ? `Ganador: ${ganadores[index + premio]}` : "Pendiente"}</div>
            </div>
          ))}
        </div>
        {Object.keys(ganadores).length > 0 ?
          <Button onClick={descargarCSV} style={{ position: 'absolute', bottom: 15, backgroundColor: '#4CAF50' }}>
            Descargar Ganadores
          </Button>
          : <></>}
      </PrizeList>
    </AppContainer>
  );
};

export default App;